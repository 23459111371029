<template>
  <b-card>
    <b-tabs content-class="mt-3" justified>
      <b-tab :title="$t('mainData')" active>
        <g-form @submit="save">
          <b-row>
            <b-col md="8" class="payment-section p-1">
              <b-row>
                <b-col md="4">
                  <!-- code  -->
                  <g-field
                    id="code"
              type="number"
                    :value.sync="selectedItem.code"
                    label-text="code"
                    name="code"
                  />
                </b-col>
                <!-- parent accounts -->
                <b-col md="4">
                  <b-form-group>
                    <g-field
                      :value.sync="selectedItem.parentId"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="parentAccount"
                      field="select"
                      name="areaId"
                      :options="parentAccounts"
                      @change="(v) => {
                        checkParent(v);
                      }"
                      label="arabicName"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <!-- arabicName  -->
                  <g-field
                    id="arabicName"
                    :value.sync="selectedItem.arabicName"
                    label-text="arabicName"
                    v-manage-value="selectedItem.id ? '' : $refs.englishName"
                    rules="required"
                    name="arabicName"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <!-- englishName  -->
                  <g-field
                    ref="englishName"
                    :value.sync="selectedItem.englishName"
                    label-text="englishName"
                    name="englishName"
                  />
                </b-col>
                <!-- account type  -->
                <b-col md="4">
                  <b-form-group>
                    <g-field
                      :value.sync="selectedItem.type"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="accountType"
                      field="select"
                      name="areaId"
                      :disabled="selectedItem.children !== undefined && selectedItem.children.length > 0"
                      :options="accountTypes"
                      label="arabicName"
                      rules="required"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <!-- level  -->
                  <g-field
                    id="level"
                    :value.sync="selectedItem.level"
                    label-text="level"
                    name="level"
                    type="number"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row>
                <!-- prevDepit  -->
                <b-col
                  md="4"
                >
                  <g-field
                    id="prevDebit"
                    :value.sync="selectedItem.prevDebit"
                    label-text="prevDebit"
                    name="prevDebit"
                    type="number"
                    :disabled="selectedItem.type === 'main'"
                  />
                </b-col>
              </b-row>
              <b-row>
                <!-- prevCredit  -->
                <b-col
                  md="4"
                >
                  <g-field
                    id="prevCredit"
                    :value.sync="selectedItem.prevCredit"
                    label-text="prevCredit"
                    name="prevCredit"
                    type="number"
                    :disabled="selectedItem.type == 'main'"
                  />
                </b-col>
                <!-- currentCredit  -->
                <b-col md="4">
                  <g-field
                    id="currentDebit"
                    :value.sync="selectedItem.currentDebit"
                    label-text="currentDebit"
                    name="currentDebit"
                    type="number"
                    disabled
                  />
                </b-col>
                <!-- currentCredit  -->
                <b-col md="4">
                  <g-field
                    id="currentCredit"
                    :value.sync="selectedItem.currentCredit"
                    label-text="currentCredit"
                    name="currentCredit"
                    type="number"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row>
                <!-- notes -->
                <b-col md="12">
                  <b-form-group :label="$t('notes')">
                    <b-form-textarea
                      id="textarea"
                      v-model="selectedItem.notes"
                      label-text="Notes"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center mt-1">
                  <b-button
                  data-action-type="new"
                    @click="
                      () => {
                        selectedItem.id = 0;
                        selectedNode.id = 0;
                        selectedItem.code = '';
                        selectedItem.arabicName = '';
                        selectedItem.englishName = '';
                        selectedItem.accountType = null;
                        selectedItem.prevDebit = 0;
                        selectedItem.prevCredit = 0;
                        selectedItem.currentDebit = 0;
                        selectedItem.currentCredit = 0;
                      }
                    "
                    variant="instagram"
                    v-permission="'addCostCenters'"
                  >
                    {{ $t('new') }}
                  </b-button>
                  <b-button class="mr-50 ml-50" type="submit" variant="primary" data-action-type="save" v-permission="'addCostCenters'">
                    {{ $t('save') }}
                  </b-button>
                  <b-button
                  data-action-type="delete"
                  :disabled="selectedItem.children !== undefined && selectedItem.children.length > 0"
                    @click="remove(selectedItem)"
                    variant="danger"
                    v-permission=" 'deleteCostCenters' "
                  >
                    {{ $t('delete') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4" class="payment-section p-2">
              <g-field
                   ref="search"
                  :value.sync="searchText"
                  label-text="search"
                  name="search"
                  />
             <v-treeview
                :value="active"
                v-model="items"
                :searchText="searchText"
                rounded
                :items="items"
                :treeTypes="treeTypes"
                item-key="id"
                :dir="isRight ? 'ltr' : 'ltr'"
                @selected="(v) => (selectedNode = v.model)"
              >
              </v-treeview>
            </b-col>
          </b-row>
        </g-form>
      </b-tab>
      <b-tab :title="$t('tabelView')">
        <b-row>
          <b-col md="12">
            <g-table
            ref="costCenter-table"
            :items="tableData"
            :columns="tableColumns"
            :noAction="true"
            perPage="25"
            :totalRows="totalRows"
            :createButton="{ visiable: false }"
            :searchInput="{ visiable: true }"
          >
          </g-table>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import VTreeview from 'v-treeview';
import {
  AccountTypes,
  AccountNatures,
  FinalAccountTypes,
} from '@/libs/acl/Lookups';
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
    VTreeview,
  },
  data() {
    return {
      searchText: '',
      active: [],
      open: [],
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      totalRows: 0,
      items: [],
      selectedItem: {
        englishName: ''
      },
      parentAccounts: [],
      accountTypes: AccountTypes,
      accountNatures: AccountNatures,
      finalAccountTypes: FinalAccountTypes,
      tableData: [],
      openAll: true,
      treeTypes: [
        {
          type: '#',
          max_children: 6,
          max_depth: 4,
          valid_children: [
            'FMM_EMPLOYEE',
            'FMM_SPOUSE',
            'FMM_CHILD',
            'FMM_SIBLING',
            'FMM_PARENT',
            'FMM_PARENT_IN_LAW',
          ],
        },
        {
          type: 'main',
          icon: 'far fa-folder-open',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'sub',
          icon: 'far fa-file',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'FMM_CHILD',
          icon: 'far fa-user',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'FMM_SIBLING',
          icon: 'far fa-user',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'FMM_PARENT',
          icon: 'far fa-user',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'FMM_PARENT_IN_LAW',
          icon: 'far fa-user',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'Basic',
          icon: 'far fa-hospital',
          valid_children: ['Top-up'],
        },
        {
          type: 'Top-up',
          icon: 'far fa-plus-square',
          valid_children: [],
        },
      ],
      contextItems: [],
      selectedNode: null,
      currentBalance: 0
    };
  },
  watch: {
    selectedNode(newVal) {
      this.selectedItem = newVal;
    },
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        { key: this.isRight ? 'arabicName' : 'englishName', label: this.$t('accountName'), sortable: true },
        {
          key: this.isRight ? 'typeArabicName' : 'typeEnglishName',
          label: this.$t('accountType'),
          sortable: true,
        },
        { key: 'level', label: this.$t('level'), sortable: true },
        {
          key: 'currentBalance',
          label: this.$t('currentBalance'),
          sortable: true,
          type: 'number'
        },
      ];
    },
  },
  mounted() {
    this.getParentAccounts();
    this.getTableData();
  },
  methods: {
    checkParent(v) {
      if (v.id === this.selectedNode.id) {
        this.doneAlert({ title: this.$t('noAllowedToAddParentToItSelf'), type: 'error' });
        this.selectedItem.parentId = null;
      }
    },
    getParentAccounts() {
      this.get({ url: `Costcenters?type=main&branshId=${this.branchId}` }).then((data) => {
        this.parentAccounts = data;
      });
    },
    getTableData() {
      this.get({ url: 'Costcenters' }).then((data) => {
        data.forEach((item) => {
          const types = this.accountTypes.find(
            (val) => val.id === item.type
          ) || { arabicName: '', englishName: '' };
          const finalAccount = this.finalAccountTypes.find(
            (val) => val.id === item.finalAccountType
          ) || { arabicName: '', englishName: '' };
          const natureAccount = this.accountNatures.find(
            (val) => val.id === item.nature
          ) || { arabicName: '', englishName: '' };
          // item.currentBalance = 0
          item.currentBalance = (item.currentDebit + item.prevDebit)
             - (item.currentCredit + item.prevCredit);
          item.text = `${item.code} - ${item.arabicName}  ( ${item.currentBalance} )`;
          item.typeArabicName = types.arabicName;
          item.typeEnglishName = types.englishName;
          item.finalArabicName = finalAccount.arabicName;
          item.finalEnglishName = finalAccount.englishName;
          item.natureArabicName = natureAccount.arabicName;
          item.natureEnglishName = natureAccount.englishName;
        });
        this.tableData = data;
        const items = this.tableData.filter((item) => !item.parentId);
        const innerItems = this.tableData.filter((item) => item.parentId);
        innerItems.forEach((element) => {
          element.children = [];
          const parent = this.tableData.find(
            (item) => item.id === element.parentId
          );
          if (parent.children) parent.children.push(element);
          else parent.children = [element];
        });
        this.items = items;
      });
    },
    beforeSaveValidation() {
      if (this.selectedItem.id > 0 && this.selectedItem.id === this.selectedItem.parentId) {
        // alert here
        this.doneAlert({ text: this.$t('noAllowedToAddParentToItSelf'), type: 'error' });
        return false;
      }
      return true;
    },
    save() {
      if (!this.beforeSaveValidation()) return;
      if (!this.selectedItem.branchId) {
        this.selectedItem.branchId = this.branchId
      }
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'Costcenters',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            this.getTableData();
            this.getParentAccounts();
          }).catch(({ data }) => {
          this.doneAlert({ title: data.detail, type: 'error' });
        });
      } else {
        this.create({ url: 'Costcenters', data: this.selectedItem })
          .then((data) => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            this.selectedItem.id = data.id;
            this.getTableData();
            this.getParentAccounts();
          }).catch(({ data }) => {
          this.doneAlert({ title: this.$t(data.detail), type: 'error' });
        });
      }
    },
    beforeRemoveValidation() {
      if (this.selectedItem.children && this.selectedItem.children.length > 0) {
        // alert here
        this.doneAlert({ text: this.$t('thisAccountHasChildren'), type: 'error' });
        return false;
      }
      return true;
    },
    remove(item) {
      if (!this.beforeRemoveValidation()) return;
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'Costcenters', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getParentAccounts();
            this.getTableData();
            this.selectedItem = {};
            this.selectedNode.id = 0;
          });
        }
      );
    },
  },
};
</script>

<style scoped>
[data-v-9d9f528c] {
  text-align: right !important;
  padding-left: 0 !important;
}
</style>
